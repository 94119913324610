/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./location.css";

const Location = () => {
  return (
    <div>
      <div className="map-iframe">

        <iframe
         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.0846614726697!2d46.76182212395677!3d24.6896162520713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f07c28b0a7c93%3A0x34d4911dcd1bbfda!2zQ2FyZSBhbmQgY3VyZSB0cmFkZSBjb21wYW55INi02LHZg9ipINin2YTYsdi52KfZitipINmI2KfZhNi52YbYp9mK2Kkg2YTZhNiq2KzYp9ix2Kk!5e0!3m2!1sar!2seg!4v1722629687486!5m2!1sar!2seg"
          width="100%"
          height="600"
          allowfullscreen=""
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>

        
      </div>
    </div>
  );
};

export default Location;
