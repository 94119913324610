


import ReactDOM from "react-dom/client";
import "./index.css";

import global_en from "./translations/en/global.json";
import global_ar from "./translations/ar/global.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

const root = ReactDOM.createRoot(document.getElementById("root"));
i18next.init({
    interpolation: { escapeValue: false },
    lng: "ar",
    resources: {
      ar: {
        global: global_ar,
      },
      en: {
        global: global_en,
      },
    },
  });
root.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
);
