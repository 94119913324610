import MainTitle from "../../components/title/MainTitle";
import "./Brands.css";
import useHeader from "../../Custom Hooks/headerCustom";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
function Products() {
  const [t] = useHeader();
  return (
    <section
      className="products-section"
      style={{ paddingTop: window.location.pathname === "/Brands" && "190px" }}
    >
      <MainTitle text={t("brand.brandOne")} />
      <div className="container">
        <SwiperSliderBrands />
      </div>
    </section>
  );
}

const SwiperSliderBrands = () => {
  const swiperRef = useRef(null);
  const [t] = useTranslation("global");
  useEffect(() => {
    if (swiperRef.current && swiperRef.current.autoplay) {
      swiperRef.current.autoplay.start();
    }
  }, [swiperRef]);

  return (
    <Swiper
      ref={swiperRef}
      spaceBetween={20}
      loop={true}
      autoplay={{
        delay: 800,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Pagination, Navigation]}
      navigation={true}
      slidesPerView={4}
      breakpoints={{
        100: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1280: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      }}
    >
      <SwiperSlide>
        <img
          src="https://www.agneseangelini.com/wp-content/uploads/2019/04/coco.jpg"
          alt={t("brand.brandKeyWordFour")}
        />
        <h1>{t("brand.brandKeyWordFour")}</h1>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://thumbs.dreamstime.com/b/dior-logo-editorial-vector-isolated-white-background-dior-logo-273033830.jpg"
          alt={t("brand.brandKeyWordThree")}
        />
        <h1>{t("brand.brandKeyWordThree")}</h1>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAW5U7yCYvyiAwgm3Cw8I-qDs-iipEq-ve4A&s"
          alt={t("brand.brandKeyWordTwo")}
        />
        <h1>{t("brand.brandKeyWordTwo")}</h1>
      </SwiperSlide>

      <SwiperSlide>
        <img
          src="https://mms.businesswire.com/media/20230428005335/en/1778093/23/TOM_FORD_BLACK_002.jpg"
          alt={t("brand.brandKeyWordOne")}
        />
        <h1>{t("brand.brandKeyWordOne")}</h1>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://www.theexchange.my/contentassets/b5267b4781a346cfa82366b273fe9076/benefit-logo-.png?width=300&height=400&upscale=false&mode=max&quality=80"
          alt={t("brand.brandKeyWordEight")}
        />
        <h1>{t("brand.brandKeyWordEight")}</h1>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://www.hypely.me/images/TDPdubai/brands/brand_logo_y8ivifje3t7p.jpg"
          alt={t("brand.brandKeyWordSeven")}
        />
        <h1>{t("brand.brandKeyWordSeven")}</h1>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://www.medoget.com/cdn/shop/collections/NYX.jpg?v=1713358533"
          alt={t("brand.brandKeyWordSix")}
        />
        <h1>{t("brand.brandKeyWordSix")}</h1>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/d/df/Elf_new_logo.png"
          alt={t("brand.brandKeyWordFive")}
        />
        <h1>{t("brand.brandKeyWordFive")}</h1>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://th.bing.com/th/id/OIP.7N09fZI1MnMiLMROnKK_FgHaHa?rs=1&pid=ImgDetMain"
          alt={t("brand.brandKeyWordTwilOne")}
        />
        <h1>{t("brand.brandKeyWordTwilOne")}</h1>
      </SwiperSlide>

      <SwiperSlide>
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQII9BTdf536Decm_tw-g3eoG7iVvaQizdr5A&s"
          alt={t("brand.brandKeyWordTwil")}
        />
        <h1>{t("brand.brandKeyWordTwil")}</h1>
      </SwiperSlide>

      <SwiperSlide>
        <img
          src="https://cdn.cookielaw.org/logos/9eb64978-23c0-4924-97a4-03eb6aab1106/42b7e788-72a8-402b-abb4-8e095a696386/472e7c95-4717-4044-ac85-5a696ef95d4a/Cetaphil_Logo.jpg"
          alt={t("brand.brandKeyWordTen")}
        />
        <h1>{t("brand.brandKeyWordTen")}</h1>
      </SwiperSlide>

      <SwiperSlide>
        <img
          src="https://upload.wikimedia.org/wikipedia/en/thumb/0/07/Arm_%26_Hammer_logo.svg/1200px-Arm_%26_Hammer_logo.svg.png"
          alt={t("brand.brandKeyWordNine")}
        />
        <h1>{t("brand.brandKeyWordNine")}</h1>
      </SwiperSlide>

      <SwiperSlide>
        <img
          src="https://mdc.rinascente.it/pub/media/aw_sbb/brand/THE_ORDINARY.png"
          alt={t("brand.brandKeyFour")}
        />
        <h1>{t("brand.brandKeyFour")}</h1>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWwqKDuEzShoYSMPG2JIHs_NHFHTW_XJ9cmg&s"
          alt={t("brand.brandKeyThree")}
        />
        <h1>{t("brand.brandKeyThree")}</h1>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdqOqrtF2WOVT1yTm-5K8od2BHVl2yqkkp1Q&s"
          alt={t("brand.brandKeyTwo")}
        />
        <h1>{t("brand.brandKeyTwo")}</h1>
      </SwiperSlide>

      <SwiperSlide>
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQU0v1vCOe2wnjRkkGi3xkIeQF-FM9h1J9eng&s"
          alt={t("brand.brandKeyOne")}
        />
        <h1>{t("brand.brandKeyOne")}</h1>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://www.leapingbunny.org/sites/default/files/brand_logo/giovanni.jpg"
          alt={t("brand.brandKeyEight")}
        />
        <h1>{t("brand.brandKeyEight")}</h1>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://cdn.salla.sa/RYWQX/9ciZxahXNipAO4q6EUYmZc5fcljjk1w2n5FpHbMF.png"
          alt={t("brand.brandKeySeven")}
        />
        <h1>{t("brand.brandKeySeven")}</h1>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://ozone-pharmacy.com/media/codazon_cache/brand/1200x/wysiwyg/Brands/MIELLE.png"
          alt={t("brand.brandKeySix")}
        />
        <h1>{t("brand.brandKeySix")}</h1>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="https://mma.prnewswire.com/media/1825012/La_Roche_Posay_Logo.jpg?p=facebook"
          alt={t("brand.brandKeyFive")}
        />
        <h1>{t("brand.brandKeyFive")}</h1>
      </SwiperSlide>
    </Swiper>
  );
};

export default Products;
