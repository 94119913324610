import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";

import Aos from "aos";
import "aos/dist/aos.css";

import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import Home from "./pages/home/Home";
import Contact from "./pages/contact/contact";
import { ToastContainer } from "react-toastify";
import { AboutSection } from "./pages/about/About";
import Products from "./pages/products/Brands";
import Parteners from "./pages/Parteners/Parteners";
import Footer from "./components/Footer/Footer";

import NotFound from "./pages/404/404";
import Location from "./pages/location/Location";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<AboutSection />} />
        <Route path="/Brands" element={<Products />} />
        <Route path="/Parteners" element={<Parteners />} />

        <Route path="/Location" element={<Location />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <ScrollToTop />
    </BrowserRouter>
  );
}

export default App;
