import { Link } from "react-router-dom";
import "./Home.css";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { useRef, useState, useEffect, memo } from "react";
import React from "react";
import { AboutUs, WhyUS } from "../about/About";
import useHeader from "../../Custom Hooks/headerCustom";
import MainTitle from "../../components/title/MainTitle";
import Products from "../products/Brands";
import useData from "../../data/PartenersData";
const Home = () => {
  return (
    <>
      <Hero />
      <AboutUs />
      <WhyUS />
      <Parteners />
      <Products />
    </>
  );
};

const Hero = memo(() => {
  const [t] = useTranslation("global");

  return (
    <section className="hero-section">
      <swiper-container
        className="image-swiper"
        navigation-next-el=".custom-next-button"
        navigation-prev-el=".custom-prev-button"
        pagination-clickable="true"
        pagination-dynamic-bullets="false"
        autoplay-delay="2000"
        autoplay-disable-on-interaction="false"
        center-slides="true"
        style={{
          "--swiper-pagination-color": "#fff",
          "--swiper-pagination-bullet-size": "10px",
          direction: "ltr",
        }}
      >
        <SwiperSlide>
          <img
            src={process.env.PUBLIC_URL + "/assets/background2.png"}
            alt={t("header.Care")}
            fetchpriority="high"
          />
          <div className={`content`}>
            <Counter />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={process.env.PUBLIC_URL + "/assets/background1.png"}
            alt={t("header.Care")}
            fetchpriority="high"
          />
          <div className={`content`}>
            <p>{t("header.overviewHeading1")}</p>
            <div className="hero-links">
              <Link to={"/contact"}>{t("header.contact")}</Link>
              <Link to={"/about"}>{t("header.about")}</Link>
            </div>
          </div>
        </SwiperSlide>
      </swiper-container>
      <div className="nav-btn custom-prev-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="main-grid-item-icon"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <line x1="19" x2="5" y1="12" y2="12" />
          <polyline points="12 19 5 12 12 5" />
        </svg>
      </div>

      <div className="nav-btn custom-next-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="main-grid-item-icon"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <line x1="5" x2="19" y1="12" y2="12" />
          <polyline points="12 5 19 12 12 19" />
        </svg>
      </div>
    </section>
  );
});

const Counter = () => {
  const [counterValues, setCounterValues] = useState({
    customers: 0,
    years: 0,
    partners: 0,
  });

  const [isSectionToggled, setIsSectionToggled] = useState(true);

  const valueDisplays = useRef(null);
  const [t] = useHeader();

  useEffect(() => {
    const interval = 5000;
    const endValues = {
      customers: 100,
      years: 190,
      partners: 40,
    };

    Object.keys(endValues).forEach((key) => {
      let startValue = 0;
      let duration = Math.floor(interval / endValues[key]);

      const counter = setInterval(() => {
        startValue += 1;
        setCounterValues((prevValues) => ({
          ...prevValues,
          [key]: startValue,
        }));
        if (startValue === endValues[key]) {
          clearInterval(counter);
        }
      }, duration);
    });
  }, []);

  return (
    <div className="counter-section">
      {isSectionToggled && <button
        className="toggler"
        onClick={() => setIsSectionToggled(false)}
      >
        {isSectionToggled && 'X'}
      </button>}
      {isSectionToggled && (
        <div className={`wrapper ${isSectionToggled ? "animate" : ""}`}>
          <div className="wrapper-container">
            <span className="text">{t("header.happyClients")}</span>
            <span className="num" ref={(el) => (valueDisplays.current = el)}>
              {counterValues.customers}+
            </span>
          </div>

          <div className="wrapper-container">
            <span className="text">{t("header.NumberProducts")}</span>
            <span className="num" ref={(el) => (valueDisplays.current = el)}>
              {counterValues.years}+
            </span>
          </div>

          <div className="wrapper-container">
            <span className="text">{t("header.clients")}</span>
            <span className="num" ref={(el) => (valueDisplays.current = el)}>
              {counterValues.partners}+
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
function Parteners() {
  const [t] = useHeader();
  /*eslint-disable*/
  return (
    <section className="our-Part">
      <MainTitle text={t("header.clients")} />
      <div className="container">
        <SwiperSlider />
      </div>
    </section>
  );
}
export default Home;
const SwiperSlider = () => {
  const [_, partnersData] = useData();

  const swiperRef = useRef(null);
  const [t] = useTranslation("global");
  useEffect(() => {
    if (swiperRef.current && swiperRef.current.autoplay) {
      swiperRef.current.autoplay.start();
    }
  }, [swiperRef]);

  return (
    <Swiper
      ref={swiperRef}
      spaceBetween={20}
      loop={true}
      autoplay={{
        delay: 800,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Pagination, Navigation]}
      navigation={true}
      slidesPerView={4}
      breakpoints={{
        100: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1280: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      }}
    >
      {partnersData.map((data, index) => (
        <SwiperSlide key={index}>
          <img
            src={process.env.PUBLIC_URL + data.image}
            alt={t("header.clients")}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};