import { Link } from "react-router-dom";
import "./footer.css";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [t] = useTranslation("global");
  return (
    <div className="footer">
      <div className="container">
        <div className="top">
          <div className="footer-logo">
            <Link to={"/"}>
              <img
                src={
                  "https://res.cloudinary.com/dor5fuqwl/image/upload/v1722396833/hppcabgtg8jnn1h4ca7k.webp"
                }
                alt="Logo"
              />
            </Link>
          </div>
          <p>{t("header.overviewHeading1")}</p>
          <div className="footer-uls">
            <ul className="footer-links">
              <li className="footer-link">
                <Link to={"/"}>{t("header.home")}</Link>
              </li>
              <li className="footer-link">
                <Link to={"about"}>{t("header.about")}</Link>
              </li>
              <li className="footer-link">
                <Link to={"contact"}>{t("header.contact")}</Link>
              </li>
            </ul>

            <ul className="footer-links">
              <li className="footer-link">
                <Link to={"Brands"}>{t("header.brand")}</Link>
              </li>

              <li className="footer-link">
                <Link to={"Parteners"}>{t("header.clients")}</Link>
              </li>
              <li className="footer-link">
                <Link to={"location"}>{t("header.location")}</Link>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="bottom">
          <div className="footer-icons">
            <Link to={"https://wa.me/+966555121382"}>
            <i class="fa-brands fa-whatsapp"></i>
            </Link>

            <Link to={"tel:+966555121382"}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
              </svg>
            </Link>
          </div>
        </div>
        <hr />
        <p className="copy-right">{t("header.copy")}</p>
      </div>
    </div>
  );
};

export default Footer;
