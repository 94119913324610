import MainTitle from "../../components/title/MainTitle";
import "./about.css";

import styled from "styled-components";
import useHeader from "../../Custom Hooks/headerCustom";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 20px;
  justify-content: center;
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
  cursor: pointer;
  margin-bottom: 20px;
  :hover {
    background: #f1f1f1;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const VisionBox = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid var(--second-color);
  margin-bottom: 100px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 5%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    background-image: url("/assets/header/medal.png");
    background-size: cover;
    background-position: center;
  }
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const VisionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const VisionText = styled.p`
  font-size: 18px;
  color: #666;
`;

export function AboutSection() {
  return (
    <>
      <AboutUs />
      <WhyUS />
      <OurVision />
    </>
  );
}
const OurVision = () => {
  const [t] = useHeader();
  return (
    <>
      <MainTitle text={t("hero.messageTwo")} />
      <Container>
        <VisionBox>
          <VisionTitle>{t("hero.messageThree")}</VisionTitle>
          <VisionText>{t("hero.messageSix")}</VisionText>
        </VisionBox>
        <VisionBox>
          <VisionTitle>{t("hero.messageFour")}</VisionTitle>
          <VisionText>{t("hero.messageFive")}</VisionText>
        </VisionBox>

        <VisionBox>
          <VisionTitle>{t("hero.messageOne")}</VisionTitle>
          <VisionText>{t("hero.messageSeven")}</VisionText>
        </VisionBox>
      </Container>
    </>
  );
};

export function WhyUS() {
  const [t] = useHeader();
  return (
    <section className="why-us">
      <MainTitle text={t("header.whyUs")} />
      <div className="container">
        <div className="box">
          <div className="root1">
            <div className="sec1"  data-aos="fade-right"
            data-aos-duration="1000">
              <span className="circle">
                <i className="fa-solid fa-building-shield fa-3x"></i>
              </span>
              <br />
              <h1>{t("hero.description")}</h1>
              <p>{t("hero.overviewOne")}</p>
            </div>
            <div className="sec2"  data-aos="fade-left"
            data-aos-duration="1000">
              <span className="circle">
                <i className="fa-solid fa-vial fa-3x"></i>
              </span>
              <br />
              <h1>{t("hero.descriptionTwo")}</h1>

              <p>{t("hero.overviewTwo")}</p>
            </div>
          </div>

          <div className="root3">
            <div className="sec3"  data-aos="fade-down"
            data-aos-duration="1000">
              <span className="circle">
                <i className="fa-solid fa-truck-fast fa-3x"></i>
              </span>
              <br />
              <h1>{t("hero.descriptionThree")}</h1>
              <p>{t("hero.overviewThree")}</p>
            </div>
            <div className="sec4"  data-aos="fade-up"
            data-aos-duration="1000">
              <span className="circle">
                <i className="fa-solid fa-money-bill-trend-up fa-3x"></i>
              </span>
              <br />
              <h1>{t("hero.headerSeven")}</h1>
              <p>{t("hero.overviewThree")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export function AboutUs() {
  const [t] = useHeader();
  return (
    <section
      className="about-us"
      style={{ paddingTop: window.location.pathname === "/about" && "190px" }}
    >
      <div className="container">
        <div className="row">
          <div
            className="col-md-6"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            <p className="about-us-header">{t("header.overview")}</p>
            <h1>{t("header.Care")}</h1>
            <hr />
            <p>{t("header.overviewHeading2")}</p>
            <span>{t("header.values")} </span>
            <div className="pyramid">
              <div data-aos="fade-down" data-aos-duration="1000">
                {t("header.values2")}
              </div>
              <div data-aos="fade-left" data-aos-duration="1000">
                {t("header.values4")}
              </div>
              <div data-aos="fade-left" data-aos-duration="1000">
                {t("header.values3")}
              </div>
              <div data-aos="fade-right" data-aos-duration="1000">
                {t("header.values7")}
              </div>
              <div data-aos="fade-down" data-aos-duration="1000">
                {t("header.values6")}
              </div>
              <div data-aos="fade-down" data-aos-duration="1000">
                {t("header.values5")}
              </div>
            </div>
          </div>
          <div
            className="col-md-6 "
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <img
              src={
                "https://dermshop.ca/cdn/shop/collections/IMG_7506_2.jpg?v=1556748710&width=3840"
              }
              alt={t("header.Care")}
              className="img-fluid about-img"
            />
          </div>
        </div>
      </div>
      <Link
        className="tab-Showmore download-btn"
        download={true}
         data-aos="fade-up"
            data-aos-duration="1000"
        to={
          process.env.PUBLIC_URL +
          `${
            localStorage.getItem("lang") === `"ar"`
              ? `/assets/Care&Cure.pdf`
              : `/assets/care-eng.pdf`
          }`
        }
        target="_blank"
        rel="noreferrer"
      >
        {t("header.Download")}
        <i class="fa-solid fa-download"></i>
      </Link>
    </section>
  );
}
