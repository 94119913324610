import useHeader from "../Custom Hooks/headerCustom";

function useData() {
  const [t] = useHeader();
  const partnersData = [
   
    {
      id: 1,
      image: "/assets/header/Partener1.webp",
      name: t("company.companyNameOne"),
      description: t("company.descOne"),
    },
    {
      id: 2,
      image: "/assets/header/Partener2.webp",
      name: t("company.companyNameTwo"),
      description: t("company.descTwo"),
    },
    {
      id: 3,
      image: "/assets/header/Partener3.webp",
      name: t("company.companyNameFour"),
      description: t("company.descFour"),
    },
    {
      id: 5,
      image: "/assets/header/Partener5.webp",
      name: t("company.companyNameThree"),
      description: t("company.descThree"),
    },
    {
      id: 6,
      image: "/assets/header/Partener6.webp",
      name: t("company.companyNameSeven"),
      description: t("company.descSeven"),
    },
    {
      id: 7,
      image: "/assets/header/Partener7.webp",
      name: t("company.companyNameFive"),
      description: t("company.descFive"),
    },
    {
      id: 8,
      image: "/assets/header/Partener8.webp",
      name: t("company.companyNameNine"),
      description: t("company.descNine"),
    },
    
    {
      id: 9,
      image: "/assets/header/Partener9.webp",
      name: t("company.companyNameSix"),
      description: t("company.descSix"),
    },
    {
      id: 10,
      image: "/assets/Partener10.webp",
      name: t("company.companyNameNew1"),
      description: t("company.companyDsecNew1"),
    },
    {
      id: 11,
      image: "/assets/Partener11.webp",
      name: t("company.companyNameNew2"),
      description: t("company.companyDescNew2"),
    },
  ];
  const links = [
    { url: "/", text: "header.home" },
    { url: "/about", text: "header.about" },
    { url: "/Parteners", text: "header.clients" },
    { url: "/Brands", text: "header.brand" },
    { url: "/contact", text: "header.contact" },
  ];
  return [links, partnersData];
}
export default useData;
