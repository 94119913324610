import { Link } from "react-router-dom";
import "./Parteners.css";

import { useState } from "react";
import useData from "../../data/PartenersData";

function Parteners() {
  /* eslint-disable */
  const [_, partnersData] = useData();
  const [showDescriptions, setShowDescriptions] = useState(
    partnersData.map(() => false)
  );

  const handleLinkClick = (index) => {
    const newShowDescriptions = [...showDescriptions];
    newShowDescriptions[index] = !newShowDescriptions[index];
    setShowDescriptions(newShowDescriptions);
  };
  return (
    <section className="parteners-section">
      <div className="container">
        <div className="our-partner">
          <div className="partner-grid">
            {partnersData.map((partner, i) => (
              <Partener key={partner.id}>
                <div className="partner-div"  data-aos="fade-right"
            data-aos-duration="1000">
                  <img
                    src={process.env.PUBLIC_URL + partner.image}
                    alt={partner.name}
                  />
                  <Link onClick={() => handleLinkClick(i)}>{partner.name}</Link>
                  <p
                    className={`description  ${
                      showDescriptions[i] && "description-active"
                    }`}
                  >
                    {partner.description}
                  </p>
                </div>
              </Partener>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
function Partener({ children }) {
  return children;
}
export default Parteners;
