import { Link } from "react-router-dom";
import "./header.css";
import useHeader from "../../Custom Hooks/headerCustom";
import { NavLink } from "../Navbar/Navbar";
import useData from "../../data/PartenersData";
import { useEffect, useState } from "react";

const Header = () => {
  const [t, openMenu, setOpenMenu, activeLink, setActiveLink, handleChange] =
    useHeader();
  const [links] = useData();
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (!event.target.closest(".menu-btn")) {
        setOpenMenu(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [setOpenMenu]);
  useEffect(() => {
    const handleScroll = () => {
      setScrolled((prevScrolled) => window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <TopNav />
      <DownNav>
        <div className={`header ${scrolled ? "scrolled" : ""}`}>
          <div className={`mob-menu ${openMenu ? "active" : "inactive"}`}>
            <ul className="mob-links">
              {links.map((link, index) => (
                <ItemLink
                  key={index}
                  openMenu={openMenu}
                  setOpenMenu={setOpenMenu}
                >
                  <Link onClick={handleLinkClick} to={link.url}>
                    {t(link.text)}
                  </Link>
                </ItemLink>
              ))}
              <div className="btns btns-mobile">
                <span onClick={() => handleChange("ar")}>
                  {t("header.Arabic")}
                </span>
                <div>|</div>
                <span onClick={() => handleChange("en")}>
                  {t("header.english")}
                </span>
              </div>
            </ul>
          </div>
          <div className="container">
            <div className="logo">
              <svg
                className="menu-btn"
                onClick={() => setOpenMenu(!openMenu)}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <rect
                  width="352"
                  height="32"
                  x="80"
                  y="96"
                  fill="var(--ci-primary-color, currentColor)"
                  className="ci-primary"
                />
                <rect
                  width="352"
                  height="32"
                  x="80"
                  y="240"
                  fill="var(--ci-primary-color, currentColor)"
                  className="ci-primary"
                />
                <rect
                  width="352"
                  height="32"
                  x="80"
                  y="384"
                  fill="var(--ci-primary-color, currentColor)"
                  className="ci-primary"
                />
              </svg>
              <Link to={"/"} className="logo-desktop">
                <img
                  src={
                    "https://res.cloudinary.com/dor5fuqwl/image/upload/v1722396833/hppcabgtg8jnn1h4ca7k.webp"
                  }
                  alt={t("header.Care")}
                />
              </Link>
            </div>
            <div className="links">
              <ul className="ul-links">
                {links.map((link, index) => (
                  <NavLink
                    key={index}
                    to={link.url}
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                    t={t}
                    text={link.text}
                  />
                ))}
              </ul>
            </div>
            <div className="btns btns-desktop">
              <span onClick={() => handleChange("ar")}>
                {t("header.Arabic")}
              </span>
              <div>|</div>
              <span onClick={() => handleChange("en")}>
                {t("header.english")}
              </span>
            </div>
            <Link to={"/"} className="logo-mobile">
              <img
                src={
                  "https://res.cloudinary.com/dor5fuqwl/image/upload/v1722396833/hppcabgtg8jnn1h4ca7k.webp"
                }
                alt={t("header.Care")}
              />
            </Link>
          </div>
        </div>
      </DownNav>
    </>
  );
};

// ... rest of the code remains the same ...
function DownNav({ children }) {
  return children;
}
function TopNav() {
  const [t] = useHeader();
  return (
    <div className="top-nav ">
      <div className="container">
        <div className="icons">
          <Link to={"https://wa.me/+966555121382"}>
            <i class="fa-brands fa-whatsapp"></i>
          </Link>

          <Link to={"https://www.facebook.com/"}>
            <i class="fa-brands fa-facebook-f"></i>
          </Link>
        </div>
        <div className="social-details">
          <ul>
            <li>
              <Link to="/location">
                {" "}
                <i className="fa-solid fa-location-dot"></i>{" "}
              </Link>
            </li>
            <li>
              <span className="company-number"> {t("header.AboutInfo2")} </span>
              <Link to={"tel:+966555121382"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16px"
                  fill="#fff"
                  height="16px"
                  viewBox="0 0 512 512"
                >
                  <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                </svg>
              </Link>
            </li>
            <li>
              <Link to={"mailto:Salman@care-cure-sa.com"}>
                Salman@care-cure-sa.com
              </Link>{" "}
              -{" "}
              <Link to={"mailto:Sultan@care-cure-sa.com    "}>
                Sultan@care-cure-sa.com{" "}
              </Link>{" "}
              <Link>
                <i className="fa-solid fa-envelope"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
function ItemLink({ children, setOpenMenu, openMenu }) {
  return (
    <li
      className="mob-link"
      onClick={() => {
        setOpenMenu(!openMenu);
      }}
    >
      {children}
    </li>
  );
}

export default Header;
