import { useLocalStorage } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function useHeader() {
  const [t, i18n] = useTranslation("global");
  const [lang, setLang] = useLocalStorage("lang", "ar");
  const [openMenu, setOpenMenu] = useState(false);
  const [activeLink, setActiveLink] = useState("/");


  const handleChange = (value) => {
    setLang(value);
    i18n.changeLanguage(value);
    setOpenMenu(false)
  };
/* eslint-disable */
  useEffect(() => {
    setLang(lang);
    i18n.changeLanguage(lang);
    document.body.dir = lang === 'ar' ? 'rtl' : 'ltr';
    document.title =
      lang === "ar" ? t("header.Care") : "Care & Cure trade Company ";
  }, [lang]);

  return [t, openMenu, setOpenMenu, activeLink, setActiveLink, handleChange]
}
export default useHeader