import React from "react";
import "./404.css"
const NotFound = () => {
  return (
    <div className="not-found"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height : "70vh"
      }}
    >
      <img
        style={{ width: "800px" }}
        src={process.env.PUBLIC_URL + "/assets/404.png"}
        alt="Error 404 Not Found"
      />
    </div>
  );
};

export default NotFound;
