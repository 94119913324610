import React from "react";
import "./main-title.css";

const MainTitle = ({ text }) => {
  return (
    <div className="main-heading">
      <h1 className="main-title">{text}</h1>
      <hr />
    </div>
  );
};

export default MainTitle;
