import "./contact.css";
import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import useHeader from "../../Custom Hooks/headerCustom";
import { Link } from "react-router-dom";
function Contact() {
  return (
    <>
      <ContactDetails />
    </>
  );
}
function ContactDetails() {
  const [t] = useHeader();
  useEffect(function () {
    function Animation() {
      const inputs = document.querySelectorAll(".input");

      function focusFunc() {
        let parent = this.parentNode;
        parent.classList.add("focus");
      }

      function blurFunc() {
        let parent = this.parentNode;
        if (this.value === "") {
          parent.classList.remove("focus");
        }
      }

      inputs.forEach((input) => {
        input.addEventListener("focus", focusFunc);
        input.addEventListener("blur", blurFunc);
      });
    }
    Animation();
  });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  let contactSubmitHandler = (e) => {
    e.preventDefault();
    if (!name) {
      return toast.error(t("message.headerThree"));
    }
    if (!email) {
      return toast.error(t("message.headerFour"));
    }
    if (!phone) {
      return toast.error(t("message.headerFive"));
    }
    if (!message) {
      return toast.error(t("message.headerSix"));
    }
  };

  useEffect(() => {
    const contactForm = document.querySelector(".contactus-form");
    let name = document.querySelector(".name-input");
    let email = document.querySelector(".email-input");
    let phone = document.querySelector(".phone-input");
    let message = document.querySelector(".message-input");

    contactForm.addEventListener("submit", () => {
      let formData = {
        name: name.value,
        email: email.value,
        phone: phone.value,
        message: message.value,
      };

      if (
        formData.name === "" ||
        formData.phone === "" ||
        formData.message === ""
      )
        return;
      let xhr = new XMLHttpRequest();

      xhr.open("POST", "https://care-server-rouge.vercel.app/send-message");
      xhr.setRequestHeader("content-type", "application/json");

      xhr.onload = async function () {
        try {
          toast.success(t("message.headerOne"));
          name.value = "";
          email.value = "";
          message.value = "";
          /*eslint-disable*/
        } catch (err) {
          toast.error(t("message.headerTwo"));
        }
      };

      xhr.send(JSON.stringify(formData));
    });
  }, []);

  return (
    <section className="contact-details">
      <div className="container">
        <div className="form">
          <div
            className="contact-form"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <form
              className="contactus-form"
              onSubmit={contactSubmitHandler}
              autoComplete="off"
            >
              <div className="input-container">
                <input
                  type="text"
                  name="name"
                  className="input name-input"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label htmlFor="">{t("contact.headerFive")}</label>
                <span>{t("contact.headerFive")}</span>
              </div>
              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  className="input email-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="">{t("contact.headerSix")}</label>
                <span>{t("contact.headerSix")}</span>
              </div>
              <div className="input-container">
                <input
                  type="tel"
                  name="phone"
                  className="input phone-input"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <label htmlFor="">{t("contact.headerSeven")}</label>
                <span>{t("contact.headerSeven")}</span>
              </div>
              <div className="input-container textarea">
                <textarea
                  name="message"
                  className="input message-input"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <label htmlFor="">{t("contact.headerEight")}</label>
                <span>{t("contact.headerEight")}</span>
              </div>
              <input type="submit" value={t("contact.send")} className="btn" />
            </form>
          </div>
          <div
            className="contact-info"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h3 className="title">{t("contact.headerOne")}</h3>
            <p className="text">{t("contact.headerTwo")}</p>

            <div className="info">
              <div className="information info-icons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 32 32"
                >
                  <path
                    fill="currentColor"
                    d="m16 24l-6.09-8.6A8.14 8.14 0 0 1 16 2a8.08 8.08 0 0 1 8 8.13a8.2 8.2 0 0 1-1.8 5.13Zm0-20a6.07 6.07 0 0 0-6 6.13a6.2 6.2 0 0 0 1.49 4L16 20.52L20.63 14A6.24 6.24 0 0 0 22 10.13A6.07 6.07 0 0 0 16 4"
                  />
                  <circle cx="16" cy="9" r="2" fill="currentColor" />
                  <path
                    fill="currentColor"
                    d="M28 12h-2v2h2v14H4V14h2v-2H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V14a2 2 0 0 0-2-2"
                  />
                </svg>
                <p>{t("header.loc")}</p>
              </div>

              <div className="information info-icons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm8-7l8-5V6l-8 5l-8-5v2z"
                  />
                </svg>
                <p>
                  <Link to={"mailto:Salman@care-cure-sa.com"}>
                    Salman@care-cure-sa.com
                  </Link>{" "}
                  -{" "}
                  <Link to={"mailto:Sultan@care-cure-sa.com    "}>
                    Sultan@care-cure-sa.com{" "}
                  </Link>{" "}
                </p>
              </div>

              <Link to={"tel:+966555121382"} className="transafer-icon">
                <div className="information info-icons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24c1.12.37 2.33.57 3.57.57c.55 0 1 .45 1 1V20c0 .55-.45 1-1 1c-9.39 0-17-7.61-17-17c0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1c0 1.25.2 2.45.57 3.57c.11.35.03.74-.25 1.02z"
                    />
                  </svg>
                  <p>{t("header.AboutInfo2")}</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Contact;
