import { Link } from "react-router-dom";
export function NavLink({ to = '', activeLink ='', setActiveLink ='', t = '', text = '' }) {
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };
    return (
      <li className="li-link">
        <Link
          to={to}
          onClick={() => {
            setActiveLink && setActiveLink(to)
            handleLinkClick()
          }}
          className={activeLink === to ? "active" : ""}
        >
          {" "}
          {t(text)}
        </Link>
      </li>
    );
  }
  